.report-type-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 0.5rem;
}

.report-card {
    cursor: pointer;
    border: 1px solid #FF7200;
    border-radius: 0.25rem;
    color: #FF7200;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.75rem 1rem;
    /* height: 7rem; */
    transition: all 0.2s;
}

.report-card:hover {
    background-color: #FF7200;
    color: white;
    scale: 1.02;
}

.icon-report {
    height: 1.5rem;
    width: 1.5rem;
    fill: #FF7200;
}

.icon-report-fa {
    font-size: 1.5rem;
}

.report-card:hover .icon-report {
    fill: white;
}

.report-txt {
    font-size: 1rem;
    font-weight: 600;
}



.field-mapping-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.field-mapping-cont section {
    border: 2px solid #FF7200;
    border-radius: 0.25rem;
    flex-basis: 40%;
}

.field-mapping-cont section .head {
    background-color: #FF7200;
    color: whitesmoke;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    padding: 0.25em;
}

.field-mapping-cont section .body {
    height: 400px;
    overflow-y: auto;
}

.field-mapping-cont section .body>div {
    width: 70%;
    margin: 0 auto;
    margin-top: 0.5rem;
    font-size: 1rem;
}

.field-mapping-cont section .body>div.field-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.field-mapping-cont .field-arrow {
    height: 1.5rem;
    background-color: #FF7200;
    flex-basis: 10%;
    position: relative
}

.field-mapping-cont .field-arrow::after {
    content: '';
    position: absolute;
    left: 100%;
    top: -0.75rem;

    width: 0;
    height: 0;
    border-left: 1.5rem solid #FF7200;
    border-top: 1.5rem solid transparent;
    border-bottom: 1.5rem solid transparent;

}

.btn-back,
.nav-cont button {
    background-color: #FF7200;
    border: 1px solid #FF7200;
    border-radius: 0.25rem;
    color: white;
    padding: 0.25rem 2rem;
    font-size: 1rem;
    transition: all ease 0.2s;
    width: 12rem;
    text-align: center;
}


.btn-back:hover,
.nav-cont button:hover {
    background-color: white;
    color: #FF7200;
}

.nav-cont button.btn-excel {
    background-color: green;
    border-color: green;
}

.nav-cont button.btn-excel:hover {
    background-color: white;
    color: green;
}

.icon-cross {
    transition: all ease 0.2s;
    color: red;
    cursor: pointer;
}

.nav-cont {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.fitler-selection-cont {
    /* border: 1px solid red; */
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1rem;
}

.loader-cont {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.html-cont {
    margin-top: 1.5rem;
    height: 400px;
    overflow-y: auto;
}