.AddressDetailcontentpanel{
    margin: 8px 15px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    background: #fff;
    padding: 10px;
    height: 600px;
    transition: all 0.4s;
    overflow-x: auto;
}  

.addrDetailHeader{
    width: 100%;
    height: 35px;
    display: flex;
    position: relative;
    top: 7px;
    justify-content: space-between;
}

.addrDetailheader1{
    width: 220px;
    height: 100%;
    display: flex;
    position: relative;
    left: 12px;
}
#addDetailIcons{
    position: relative;
    font-size: 22px;
    color: #ff7200;
    top: 25%;
}
.addrDetailheader1 button{
    width: 50px;
    height: 28px;
    background-color: #ff7200;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.addrDetailheader1 .title{
    position: relative;
    top: 72%;
    font-size: 1rem;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 20px;
   
   
}
#back-arrow{
  color: #fff;
}

.addrDetailheader2{
    width: 10%;
    height: 100%;
    display: flex;
    position: relative;
    right: 32px;
    justify-content: space-between;
}
.addrDetailheader2 button{
    width: 45%;
    height: 28px;
    background-color: #fff;
    border: 1px solid #ff7200;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
#edit-addDetailsIcon{
    color: #ff7200;
    font-size: 15px;
}
#trash-icon{
    color: #ff7200;
    font-size: 15px;
}


.AddressDetailcontentpanel h1.title {
    font-size: 1.2rem;
    margin: 0;
}
.addressDetailsDiv{
    width: 100%;
    height: 100%;
    position: relative;
    top: 0px;
}
.addrDetailTable{
    width: 95%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
    top: 20px;
    border: 2px solid #E3E3E3;
    overflow-y: auto;
}
.addressDetailsInner{
    width: 49%;
    height: auto;
    position: relative;
    top: 0px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.centreAddeDetail{
    width: 0.2%;
    height: 94%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-left: 3px dashed #E3E3E3; 
}
.addressDetailsInner2{
    width: 51%;
    height: auto;
    position: relative;
    top: 0px;
   border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.addDetail2{ 
    width: 94%;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    border-left:none;
    border-left:none;
    border-top:none;
    border-bottom: 2px solid #E3E3E3;
    display: flex;
    justify-content: space-between; 
}
.addDetail2_1{
    width: 40%;
    height: 100%;
    display: flex;
}
.addDetailinner2{
    width: 60%;
    height: 100%;
    border-left:none;
    border-left:none;
    border-top:none;
    border-radius: 4px;
}
/*  */

.addDetail3{
    width: 94%;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    border-left:none;
    border-left:none;
    border-top:none;
    border-bottom: 2px solid #E3E3E3;
    display: flex;
    justify-content: space-between; 
}

.addDetail3_1{
    width: 40%;
    height: 100%;
    display: flex;
}
/*  */
.addDetail4{
    width: 94%;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    border-left:none;
    border-left:none;
    border-top:none;
    border-bottom: 2px solid #E3E3E3;
    display: flex;
    justify-content: space-between; 
}

.addDetail4_1{
    width: 40%;
    height: 100%;
    display: flex;
}
/*  */
.addDetail5{
    width: 94%;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    /* border-bottom: 2px solid #E3E3E3; */
    display: flex;
    justify-content: space-between; 
}

.addDetail5_1{
    width: 40%;
    height: 100%;
    display: flex;
}
/*  */

.addDetail6{
    width: 94%;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    border-left:none;
    border-top:none;
    border-bottom: 2px solid #E3E3E3;
    display: flex;
    justify-content: space-between; 
    overflow: auto;
}

.addDetail6_1{
    width: 30%;
    height: 100%;
    display: flex;
  
}
/*  */
.addDetail7{
    width: 94%;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    border-left:none;
    border-left:none;
    border-top:none;
    border-bottom: 2px solid #E3E3E3;
    display: flex;
    justify-content: space-between; 
}

.addDetail7_1{
    width: 40%;
    height: 100%;
    display: flex;
}
/*  */
.addDetail8{
    width: 94%;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    border-left:none;
    border-left:none;
    border-top:none;
    border-bottom: 2px solid #E3E3E3;
    display: flex;
    justify-content: space-between; 
}

.addDetail8_1{
    width: 40%;
    height: 100%;
    display: flex;
}
/*  */
.addDetail9{
    width: 94%;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    display: flex;
    justify-content: space-between; 
}

.addDetail9_1{
    width: 40%;
    height: 100%;
    display: flex;
}
/*  */
.addHeadingdiv span{
    position: relative;
    font-size: 0.9rem;
    color: #959595;
    font-weight: 600;
    top: 35%;
    left: 10px;
    font-family: GOTHIC;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.addDetailinner2 span{
    position: relative;
    font-size: 0.9rem;
    color: #ff7200;
    font-weight: 600;
    top: 32%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: GOTHIC;
}





































