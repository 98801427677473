.modal, .overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: block !important;
    
}

.overlay{
    background-color: rgba(49,49,49,0.8);
}

.modal_content{
    width: 42%;
    height: 400px;
    position: absolute;
    top: 350px;
    left: 50%;
    transform: translate(-50%,-50%);
    line-height: 1.1;
    background-color: #ff7200;
    border-radius: 10px;   
    text-align: center;
}

.modal_content h3{
    color: #fff;
    font-size: 1.3rem;
    position: relative;
    top: 14px;
}

.driver_details{
    width: 97%;
    height: 86%;
    position: relative;
    top: 18px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    overflow: auto;
    /* z-index: 100; */
}
.cust-row_driver{
    width: 100%;
    display: flex;
    position: relative;
    top: 22px;
}

.cust-row_driver>.cust-col-10{
    width: 100%;
    position: relative;
    padding: 5px 10px;
}
.table-responsive_driver{
    position: relative;   
}

.table-responsive_driver .add-bordered_table{
    width: 90%;
    height: 250px;
    border-collapse: collapse;
    padding: 2px;
    border: 1px solid #727376;
    /* border-radius: 7px; */
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}
.addDriver-detail1{
    width: 90%;
    height: 42px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    border-bottom: 1px solid #888;
    
   
}
.addDriver-Left{
    width: 50%;
    height: 100%;
    border-right: 1px solid #888;
}
.addDriver-Left span{
    font-size: 0.9rem;
    color: black;
    display: flex;
    text-align: flex-start;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
}
.addDriver-right input{
    width: 100%;
    height: 100%;
    padding-left: 14px;
    font-size: 0.95rem;
    color: #ff7200;
    border: none;
    letter-spacing: 0.5px;
    font-weight: 600;
}



.addDriver-right{
    width: 50%;
    height: 100%;
}
.addDriver-detail2{
    width: 90%;
    height: 42px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    border-bottom: 1px solid #888;
}
.addDriver-detail3{
    width: 90%;
    height: 42px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    border-bottom: 1px solid #888;
}
.addDriver-detail4{
    width: 90%;
    height: 42px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.add_div{
    width: 100%;
    height: 30px;
    position: relative;
    top: 40px;

}
.add_div button{
    width: 16%;
    height: 100%;
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    border: none;
    font-size: 0.95rem;
    cursor: pointer;
}
















