@font-face {
    font-family: myFirstFont;
    src: url(../fonts/Futura_Md_BT_Bold.ttf);
}

@font-face {
    font-family: mediumFont;
    src: url(../fonts/Futura_Md_BT_Medium.ttf);
}

.sidebar ul li a {
    font-family: myFirstFont;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: GOTHIC;
}

body::-webkit-scrollbar {
    width: 0.4em;
}
   
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
   
body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.heading {
    color: #1a3c4c;
    font-size: 60pt;
    margin-top: 80px;
    font-weight: 900;
    line-height: 1;
}
.warning_note{
    margin-bottom: 0;
    margin-left: 10px;
    color: #ea5e5e;
    font-size: 14px;
}

.bg-gradient {
    background: linear-gradient(45deg, #fff, #ff7200) !important;
}

.navbaru{
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbaru>.logo_section{
    width: 20%;
    text-align: center;
}

.navbaru>.menu_section{
    width: 80%;
    text-align: center;
}

.navbaru>.btn_section{
    width: 20%;
    text-align: center;
}

.main-content-sec{
    height: calc(100vh - 100px);
}

.footer-sec{
    height: 40px;
    background: #727376;
    color: #FFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-sec>p{
    margin-bottom: 0;
}

@media screen and (min-width: 1441px) {
    .heading {
        font-size: 70pt;
        margin-top: 90px;
    }
}

@media screen and (min-width: 1300px) {
    .heading {
        font-size: 45pt;
    }
}

@media screen and (max-width: 1299px) {
    .heading {
        font-size: 40pt;
    }

    .para-overmargin{
        margin-right: -150px !important;
    }
}

@media screen and (max-width: 1024px) {
    
}


/* On screens that are 600px wide or less, the background color is olive */

@media screen and (max-width: 768px) {
    .heading {
        margin-top: 60px;
        font-size: 30pt;
    }

    .about-title{
        padding-left: 40px !important;
        font-size: 1rem;
    }

    .about-title::before{
        width: 30px !important;
    }

    .row-div>div>h4{
        font-size: 1.2rem !important;
    }

    .row-div>div>p {
        font-size: 0.8rem !important;
    }

    .row-div>div>img{
        width: 100%;
    }

    .banner-section>.row-div {
        padding: 4% 6% !important;
    }

    .banner-section>.row-div>div>h3 {
        font-size: 1.2rem !important;
    }

    .banner-section>.row-div>div>input {
        width: 100% !important;
        height: 35px !important;
        border-radius: 10px !important;
        padding: 5px !important;
    }

    .contact-section>.row-div>div>button, .banner-section>.row-div>div>button {
        border-radius: 10px !important;
        height: 30px !important;
        padding: 0 15px !important;
    }

    .contact-section>.row-div>div>p {
        padding: 0% 15% !important;
    }

    .contact-section>.row-div>div>h2 {
        font-size: 1.4rem !important;
    }

    .footer-section>p {
        text-align: center;
    }

    .bg_image{
        background-position: center !important;
    }

    .row-div.nav-div>div:nth-child(1){
        width: 10%;
    }

    .row-div.nav-div>div>img{
        height: 60px;
        width: 80px;
    }

    .row-div.nav-div>div:nth-child(2){
        width: 80%;
    }

    .row-div.nav-div>div.right-content>ul>li>a{
        padding: 0.1rem 0.5rem !important;
        font-size: 0.8rem !important;
    }

    .custom-modal {
        width: 80% !important;
    }

    .para-overmargin{
        margin-right: 0px !important;
    }

}

@media screen and (max-width: 425px) {
    .heading {
        margin-top: 30px;
        font-size: 20pt;
    }
}


.right-content{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.logo{
    height: 70px;
    margin-left: 15%;
}

.about-section,.banner-section,.contact-section{
    width: 100%;
    position: relative;
    padding: 10px 20px;
}

.row-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
}

.banner-section>.row-div{
    padding: 4% 15%;
    background: #ea9869;
    margin: 2%;
}

.banner-section>.row-div>div>h3{
    font-size: 2.5rem;
    color: #fff;
}

.banner-section>.row-div>div>input{
    width: 80%;
    height: 45px;
    display: block;
    margin-bottom: 12px;
    border-radius: 15px;
    border: 2px solid #fff;
    outline: none;
    padding: 5px 10px;
}

.contact-section>.row-div>div>button,.banner-section>.row-div>div>button{
    background: #1a3c4c;
    color: #fff;
    border: 2px solid #1a3c4c;
    border-radius: 15px;
    height: 40px;
    padding: 0 20px;
    cursor: pointer;
    outline: none;
}

.row-div>div{
    width: 40%;
}

.contact-section>.row-div{
    justify-content: center;
    margin-bottom: 20px;
}

.contact-section>.row-div>div{
    width: 80% !important;
    text-align: center;
}

.contact-section>.row-div>div>h2{
    font-size: 2.6rem;
}
.contact-section>.row-div>div>p{
    padding: 0% 25%;
}

.row-div>div>h4{
    font-size: 4rem;
}

.row-div>div>p{
    font-size: 1.2rem;
    font-size: 1rem;
}

.para-overmargin{
    margin-right: -120px;
}

.about-title{
    font-family: GOTHIC;
    padding-left: 200px;
    position: relative;
}

.about-title::before{
    content: '';
    position: absolute;
    border: none;
    border-top: 2px dotted #000;
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 180px;
    left: 0px;
    top: 50%;
}

.contact-section>.row-div>div>button{
    padding: 0 50px;
}

.contact-section>.row-div>div>button:nth-child(1){
    margin-right: 10px;
}

.contact-section>.row-div>div>button:nth-child(2){
    margin-left: 10px;
    background: #fda33b;
    border: 2px solid #fda33b;
}

.footer-section::before{
    content: '';
    width: 100%;
    height: 15px;
    position: absolute;
    background: #143a4d;
    top: -15px;
}

.footer-section>p::before{
    content: '';
    height: 10px;
}

.footer-section>p{
    color: #fff;
    margin-left: 4%;
    margin-bottom: 0;
}

.footer-section{
    background: #061f2b;
    position: relative;
    margin-top: 15px;
    height: 40px;
    padding-top: 6px;
}

.scroll-icon{
    position: fixed;
    right: 10px;
    bottom: 20px;
    background: #061f2b;
    height: 50px;   
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: none;
    transition: all 0.5s;
}

.scroll-icon::after{
    content: '';
    width: 16px;
    height: 16px;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate(-45deg);
    box-sizing: border-box;
    margin-top: 5px;
}

.custom-modal {
    background-color: #FF7200;
    border-radius: 10px;
    display: none;
    max-height: calc(100% - 100px);
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    z-index: 9999;   
    width: 60%;
    padding-right: 0 !important;
    padding: 10px;
}

.custom-modal.bg-wht{
    background-color: #FFF;
}

.custom-modal .custom-modal-header{
    color: #FFF;
    text-align: center;
}

.custom-modal.bg-wht .custom-modal-header{
    color: #000;
}

.custom-modal .custom-modal-header h1{
    color: #FFF;
    text-align: center;
    font-size: 1.3rem;
}

.custom-modal.bg-wht .custom-modal-header h1{
    color: #000;
}

.custom-modal.custom-modal-is-visible {
    display: block;
    z-index: 9999;
}

.custom-modal .custom-modal-body {
    background: #fff;
    width: calc(100% - 10px);
    padding: 20px 10px 10px 10px;
    text-align: center;
}
  
.custom-modal-overlay {
    background-color: #00000087;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 980;
    
}

.custom-modal-overlay.custom-modal-is-visible {
    display: block;
    z-index: 2001;
}

.ct-close{
    position: absolute;
    height: 40px;
    width: 40px;
    background: #fff;
    top: -25px;
    right: -15px;
    border-radius: 50%;
    display: flex;  
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.custom-modal.bg-wht .ct-close{
    background: #727376;
}

.ct-close::before{
    content: ' ';
    width: 15px;
    height: 2px;
    position: relative;
    background: #FF7200;
    transform: rotate(45deg);
}

.ct-close::after{
    content: ' ';
    width: 15px;
    height: 2px;
    position: relative;
    background: #FF7200;
    margin-left: -15px;
    transform: rotate(-45deg);
}

.custom-modal.bg-wht .ct-close::before,.custom-modal.bg-wht .ct-close::after{
    background: #FFF;
}

.custom-modal-body>.row-div{
    padding: 0;
    margin-bottom: 15px;
}

.custom-modal-body>.row-div>div>button{
    background-color: #727376;
    color: #fff;
    margin-right: 12px;
    border: 2px solid #727376;
    border-radius: 5px;
    outline: none;
    padding: 2px 10px;
    font-size: 18px;
    cursor: pointer;
}

.custom-modal-body>.row-div>div:nth-child(2)>button{
    background-color: #e61a10;
    border: 2px solid #e61a10;
}

.custom-modal-body>.row-div>div{
    width: 50%;
}

.custom-modal-body>.row-div>div>input,.custom-modal-body>.row-div>div>.control-input,.custom-modal-body>.row-div>div>select{
    height: 35px;
    width: 95%;
    border-radius: 5px;
    border: 1px solid #FF7200;
    padding: 0 5px;
    outline: none;
    caret-color: #FF7200;
    color: #FF7200;
}

.custom-modal-body>.row-div>div>textarea{
    padding: 5px !important;
    height: 50px !important;
    resize: none;
}

.custom-modal-body>.row-div>div>input::placeholder,.custom-modal-body>.row-div>div>textarea::placeholder{
    color: #FF7200;
    font-weight: 100;
}

#loginModal .custom-modal-body>.row-div>div{
    width: 100%;
}

input:-webkit-autofill{
    color: #FF7200;
    background: transparent;
}

.swal-text{
    text-align: center;
}

.bg_image{
    background: url(./../images/banner_bg.png);
    background-position: 0 70%;
}

.text-left{
    text-align: left !important;
}

.email_heading{
    position: absolute;
    font-size: 16px !important;
    top: 58px;
    color: #6c757d;
    /* color: #f5ab4b; */
    border-top: 2px solid #eaeaea;
    padding-top: 3px;
}

.email_heading>span{
    color: #6c757d;
    /* color: #f5ab4b; */
    text-transform: uppercase;
    cursor: pointer;
    font-size: 14px;
}

/* .nav-link {
    padding: .2rem 1rem;
} */

.nav>li.border_right>button{
    box-shadow: 2px 0px 0px #e4e4e4;
}

.cutum{
    font-family: GOTHIC;
    background: transparent;
    border: navajowhite;
    outline: none;
    cursor: pointer;
}

.cutum:hover{
    color: #545b62 !important;
}

.cutum:focus{
    outline: none;
}


/* custom select dropdown */
/*the container must be positioned relative:*/
.custom-select {
    position: relative;
    font-family: Arial;
}
  
.custom-select select {
    display: none; /*hide original SELECT element:*/
}
  
.select-selected {
    background-color: DodgerBlue;
}
  
  /*style the arrow inside the select element:*/
.select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}
  
  /*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}
  
  /*style the items (options), including the selected item:*/
.select-items div,.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    user-select: none;
}
  
  /*style items (options):*/
.select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}
  
  /*hide the items when the select box is closed:*/
.select-hide {
    display: none;
}
  
.select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}
.d-flex{
    display: flex;
}
.w-50{
    width: 50%;
    float: right;
}
.flex-wrap{
    display: flex;
    flex-wrap: wrap;
}
.cross-btn{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.f-a-cent{
    align-items: center;
}
.f-j-end{
    justify-content: flex-end;
}

.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff7200;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }