.main-content>.content-body>.contractscontentpanel {
    margin: 8px 15px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    background: #fff;
    padding: 10px;
    height: 800px;
    transition: all 0.4s;
    overflow-x: auto;
}
.contract-header{
    width: 100%;
    height: 4%;
    display: flex;
    position: relative;
    top: 8px;
    justify-content: space-between;
}
.contract-b1{
    width: 12%;
    height: 100%;
    display: flex;
    position: relative;
    left: 12px;
    justify-content: space-between;
}
.contract-b1 button{
    width: 50px;
    height: 30px;
    border: none;
    background-color: #ff7200;
    border-radius: 6px;
    cursor: pointer;
}
#contract-back{
    color: #fff;
    font-size: 16px;
}
.contract-b2{
    width: 15%;
    height: 100%;
}
.contract-b2 .contract-btn-1{
    width: 20%;
    height: 30px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #ff7200;
    border: 1px solid #fff;;
    cursor: pointer;
    color: #fff;
}

.contract-b2 .contract-btn-2{
    width: 79%;
    height: 29px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #ff7200;
    border: none;
    color: #fff;
    cursor: pointer;
}
#fa-add-icon{
    color: #fff;
}
.contract-header h1.title {
    font-size: 1.2rem;
    margin: 0;
    position: relative;
    top: 55%;
    transform: translateY(-50%);
} 
.contract-container {
    width: inherit;
    position: relative;
    padding: 5px 10px;
}
.contract-header1{
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: space-between;
}
.contractHeader1-one{
    width: 36%;
    height: 100%;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 12px;
}
.contractNum button{
    border-radius: 50%;
    background-color: #888;
    color: #ff7200;
    position: relative;  
    border: none;  
    top: -10px;
    left: -9px;
    color: white;
    cursor: pointer;
}
/*  */
.Contractone{
    width: 100%;
    height: 32%;
    display: flex;
    position: relative;
    top: 0px;
    justify-content: space-between;
}
.contractone-1{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.contractone-2{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.contractone-3{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.contractspan1{
    color:  #888; 
    font-size: 0.8rem;
    font-weight: 700;
    padding-left: 10px;
}
.contractspan2{
    font-size: 0.9rem;
    color: #ff7200;
    font-weight: 600;
    padding-left: 10px;
}
.contractspan3{
    font-size: 0.6rem;
    color: #888;
    font-weight: 600;
    padding-left: 10px;
}
/*  */
.Contracttwo{
    width: 100%;
    height: 32%;
    display: flex;
    justify-content: space-between;
   
}
.contractTwo-1{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.contractTwo-2{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}
.contractTwo-3{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
/*  */
.Contractthree{
    width: 90%;
    height: 20%;
    display: flex;
    justify-content: right;
}
.Contractthree button{
    width: 70px;
    height: 25px;
    border: 1px solid #ff7200;
    border-radius: 7px;
    background-color: #fff;
    color: #ff7200;
    position: relative;
    top: 11px;
    right: 20px;
    cursor: pointer;
}
.Contractthree button:hover{
    width: 70px;
    height: 25px;
    border: 1px solid #ff7200;
    border-radius: 7px;
    background-color: #ff7200;
    color: #fff;
    position: relative;
    top: 11px;
    right: 20px;
    cursor: pointer;
}
/*  */
.contractHeader1-two{
    width: 36%;
    height: 100%;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 12px;
}
.contractHeader1-three{
    width: 23%;
    height: 100%;
    border-radius: 12px;
    position: relative;    
}
.contactAdd{
    width: 77%;
    height: 32px;
    position: absolute;
    top: 45%;
    translate: transformY(-50%);
}

/*  */
.contract-header2{
    width: 100%;
    height: 490px;
    position: relative;
    top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 12px;
    border: 1px solid rgb(209, 209, 209);
}
.Contract-h2-div-1{
    width: 100%;
    height: 130px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.contract-h2-r1{
    width: 100%;
    height: 18%;
}
.contract-h2-r1 span{
    font-size: 1.3rem;
    padding-left: 10px;
    font-weight: 700;
}
.contract-h2-r2{
    width: 40%;
    height: 70%;
    display: flex;
    position: relative;
    left: 10px;
    justify-content: space-between;
}
.Contract-div-1-c1{
    width: 31%;
    height: 90px;
    display: flex;
    position: relative;
    justify-content: space-around;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    background-color:rgb(239, 239, 239) ;
    
}
.contract-c1-r1{
    width: 25%;
    height: 48%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: white;
}
.contract-c1-r1 img{
    width: 26px;
    height: 24px;
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    transform: translateY(-50%);
}
.contract-c1-r2{
    width: 50%;
    height: 62%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content:space-around ;
}
.Contract-div-1-c2{
    width: 31%;
    height: 90px;
    display: flex;
    position: relative;
    justify-content: space-around;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    background-color:rgb(239, 239, 239) ;
}
.Contract-div-1-c3{
     width: 31%;
    height: 90px;
    display: flex;
    position: relative;
    justify-content: space-around;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    background-color:rgb(239, 239, 239) ;
}
/*  */
.Contract-h2-div-2{
    width: 100%;
    height: 130px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Contract-h2-div-3{
    width: 100%;
    height: 130px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.contract-h2-r3{
    width: 54%;
    height: 70%;
    display: flex;
    position: relative;
    left: 10px;
    justify-content: space-between;
}
.Contract-div-r3-c1{
    width: 23%;
    height: 80px;
    display: flex;
    position: relative;
    justify-content: space-around;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    background-color:rgb(239, 239, 239) ;
}
.Contract-div-r3-c2{
    width: 23%;
    height: 80px;
    display: flex;
    position: relative;
    justify-content: space-around;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    background-color:rgb(239, 239, 239) ;
}
.Contract-div-r3-c3{
    width: 23%;
    height: 80px;
    display: flex;
    position: relative;
    justify-content: space-around;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    background-color:rgb(239, 239, 239) ;
}
.Contract-div-r3-c4{
    width: 23%;
    height: 80px;
    display: flex;
    position: relative;
    justify-content: space-around;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    background-color:rgb(239, 239, 239) ;
}
.Contract-div-1-c4{
    width: 20%;
   height: 75px;
   display: flex;
   position: relative;
   justify-content: space-around;
   border: 1px solid rgb(209, 209, 209);
   border-radius: 10px;
}
/*  */
.c1-r2-t1{
    color: #ff7200;
    font-size: 1.5rem;
    font-weight: 600;
}
.c1-r2-t2{
    color:  #888; 
    font-size: 0.7rem;
    font-weight: 700;
}













