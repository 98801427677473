.pagination-container {
    margin: 1em;
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    .prev,
    .next {
      font-size: 0.8rem;
      color: rgb(85, 91, 103);
      display: flex;
      align-items: center;
      margin: 0 0.25em;
      &:hover {
        
        cursor: pointer;
      }
      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: "";
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.15em solid #f57c00;
          border-top: 0.15em solid #f57c00;
        }
  
        &.left {
          transform: rotate(-135deg) translate(-50%);
        }
  
        &.right {
          transform: rotate(45deg);
        }
      }
      &.disabled {
        pointer-events: none;
  
        .arrow::before {
          border-right: 0.12em solid #f57c00;
          border-top: 0.12em solid #f57c00;
        }
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  
    .pagination-item {
      width: 2em;
      height: 2em;
      margin: 0 0.5em;
      color: #f2f2f2;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.01071em;
      border-radius: 50%;
      line-height: 1.43;
      font-size: 11px;
      background-color: #fff;
      color:#000;
  
      &.dots:hover {
        background-color: #ff7200;
        color:#fff;
        cursor: default;
      }
      &:hover {
        background-color: #f57c00;
        color:#fff;
        cursor: pointer;
      }
  
      &.selected {
        background-color: #f57c00;
        color:#fff;
      }
    }
  }
  