
.customMarker {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #424242;
    width: 100px;
    height: 100px;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
  
.customMarker:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 40px;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: #424242 transparent;
    display: block;
    width: 0;
  }
  .customMarker span{
    position: absolute;
    background: #fff;
    border-radius: 50%;
    right: 5%;
    font-weight: bold;
    border: 2px solid #424242;
    display: flex;
    height: 25px;
    width: 25px;
    top: 0;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
  .customMarker img {
    width: 90px;
    height: 90px;
    margin: 3px;
    border-radius: 50%;
  }
/*  */

.customMarker1 {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ff7200;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.customMarker1 div {
  position: absolute;
  top: 50%;
  left: 50%;
  background:fff;
  width: 42px;
  height: 42px;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.customMarker1:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 13px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #ff7200 transparent;
  display: block;
  width: 0;
}
















