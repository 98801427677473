.custom-input {
    display: flex;
    width: 100%;
    /* font-family: GOTHIC; */
}

.custom-input label {
    margin: 0;
    text-transform: capitalize;
}

.custom-input>label {
    flex-basis: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 0.9rem;
}

.custom-input>label.star::after {
    content: "*";
    color: red;
    margin-left: 0.25em;
}

.custom-input>input,
.custom-input>.select_wrapper {
    flex-basis: 70%;
}

.custom-input .input_group_wrapper,
.input_group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
}

.custom_checkbox {
    display: flex;
    align-items: center;
    gap: 0.75em;
}

.custom_checkbox input[type="checkbox"] {
    position: static;
    margin: 0;
}

.custom_checkbox label {
    margin: 0;
}

/* .custom_checkbox input[type="checkbox"] */
.custom-input .input_group input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
    /* width: 2em;
    height: 2em;*/
    /* border: 0.15em solid #ff7200; */
    width: 1.25em;
    height: 1.25em;
    border: 0.15em solid #f0f0f0;
    background-color: #f0f0f0;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;

    position: static;
    outline: initial !important;
    box-shadow: none !important;
    background-color: #fff !important;
}

.custom-input .input_group input[type="radio"]:disabled,
.custom_checkbox input[type="checkbox"]:disabled {
    background-color: grey;
}

.custom-input .input_group input[type="radio"]:disabled:hover,
.custom_checkbox input[type="checkbox"]:disabled:hover {
    cursor: not-allowed;
}

.custom-input .input_group input[type="radio"] {
    border-radius: 50%;
}

/* .custom_checkbox input[type="checkbox"]::before */
.custom-input .input_group input[type="radio"]::before {
    content: "";
    /* width: 1em; */
    /* height: 1em; */
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    /* box-shadow: inset 1em 1em white; */
    width: 0.75em;
    height: 0.75em;
    box-shadow: inset 1em 1em #111;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}


.custom-input .input_group input[type="radio"]:checked::before,
.custom_checkbox input[type="checkbox"]:checked::before {
    transform: scale(1);
}


.custom-button {
    cursor: pointer;
}